/* Globals */
:root,
:root.light-mode {
  --color-text-main: #404040;
  --color-text-secondary: #4d4d4d;

  --color-util-main: #e46c1c;
  --color-util-secondary: #f87f1c;

  --color-bg-main: #f2f3f5;
  --color-bg-secondary: #e5e7e9;

  --color-form-main: #dcdce1;
  --color-form-secondary: #d2d2d7;
  --color-form-disabled: #e9ecef;
  --color-form-text: #212529;

  --color-alert-success: #75b798;
  --color-alert-danger: #ea868f;
}

:root.dark-mode {
  --color-text-main: #f7f7ff;
  --color-text-secondary: #dfdfe6;

  --color-util-main: #e46c1c;
  --color-util-secondary: #f87f1c;

  --color-bg-main: #36393f;
  --color-bg-secondary: #303238;

  --color-form-main: #4d5059;
  --color-form-secondary: #545661;
  --color-form-disabled: #2f3136;
  --color-form-text: #e3e5e8;

  --color-alert-success: #198754;
  --color-alert-danger: #dc3545;
}

:root.dark-mode .text-muted {
  color: #7b868f !important;
}

/* Colors */
.bg-secondary {
  background-color: var(--color-form-secondary);
}

.bg-form-main {
  background-color: var(--color-form-main);
}

.color-text-secondary {
  color: var(--color-text-secondary);
}

/* Main Tags */
body {
  font-family: 'Ubuntu', 'Helvetica';
  overflow-y: scroll;
  color: var(--color-text-main);
}

body,
nav {
  position: relative;
  background-color: var(--color-bg-main);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Autofill Hack */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px var(--color-form-main) inset !important;
  box-shadow: 0 0 0 1000px var(--color-form-main) inset !important;
}

/* Not Selectable */
nav,
footer {
  user-select: none;
}

.badge {
  color: unset;
}

/* Navigation */
.navbar-nav .nav-link {
  color: var(--color-text-main);
}

.navbar-brand {
  color: var(--color-util-main) !important;
}

.navbar-toggler:focus {
  box-shadow: unset;
}

/* Heading */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

/* Spacing */
.mb-6 {
  margin-bottom: 5rem !important;
}

.mt-6 {
  margin-top: 5rem !important;
}

.x-expand {
  height: 100%;
}

.y-expand {
  width: 100%;
}

/* Link */
a {
  color: var(--color-util-main);
  text-decoration: none;
}

a:hover {
  color: var(--color-util-secondary);
}

.link {
  color: var(--color-util-main);
  text-decoration: none;
}

.link:hover {
  color: var(--color-util-secondary);
}

.nav-link {
  letter-spacing: 1px;
}

.nav-link:hover::before,
.nav-link.active::before {
  position: absolute;
  content: attr(data-title);
  border-bottom: 4px solid var(--color-util-main);
  color: transparent;
}

nav a.active > i {
  color: var(--color-util-main);
}

nav a:hover > i {
  color: var(--color-util-secondary);
}

/* Button */
.btn {
  border-radius: 0;
}

.btn-primary {
  background-color: var(--color-util-main);
  border-color: var(--color-util-main);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--color-util-secondary);
  border-color: var(--color-util-secondary);
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgb(var(--color-util-main) / 50%) !important;
}

.btn-primary:disabled,
.btn-primary.disabled {
  cursor: not-allowed;
  background-color: var(--color-form-disabled);
  border-color: var(--color-form-disabled);
}

.btn-sm {
  padding: 0 1em;
}

/* Form */
input[type="search"]::placeholder {
  color: var(--color-text-secondary);
}

input[type="search"]::-webkit-input-placeholder {
  color: var(--color-text-secondary);
}

input[type="search"]:-moz-placeholder {
  color: var(--color-text-secondary);
}

input[type="search"]::-moz-placeholder {
  color: var(--color-text-secondary);
}

input[type="search"]:-ms-input-placeholder {
  color: var(--color-text-secondary);
}

.form-control,
.form-control:focus {
  color: var(--color-form-text);
}

.form-control:disabled,
.form-control[readonly],
.form-select:disabled,
.form-select[readonly] {
  background-color: var(--color-form-disabled);
  cursor: not-allowed;
}

.form-floating label {
  color: var(--color-text-secondary);
  opacity: 1 !important;
}

.form-control,
.form-select {
  color: var(--color-form-text);
  border: none;
  border-radius: 0;
  background-color: var(--color-form-main);
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
  background-color: var(--color-form-secondary);
}

.modal .form-control,
.modal .form-select {
  border: none;
  border-radius: 0;
  background-color: var(--color-bg-secondary);
}

.modal .form-control:focus,
.modal .form-select:focus {
  box-shadow: none;
  background-color: var(--color-bg-main);
}

textarea:focus {
  min-height: 200px !important;
}

.alert {
  color: var(--color-form-text);
  margin-top: 0;
  padding: 1em;
}

.alert-danger {
  background-color: var(--color-alert-danger);
}

.alert-success {
  background-color: var(--color-alert-success);
}

.invalid-feedback {
  background-color: var(--color-alert-danger);
  color: var(--color-form-text);
  margin-top: 0;
  padding: 0.8em;
}

/* Modal Box */
.modal-content {
  border: 0;
  border-radius: 0;
}

/* Alert Box */
.alert {
  border-radius: 0;
  border: 0;
}

/* Statistics */
/* Comments */
.comment__item,
.statistic__item {
  background-color: var(--color-bg-secondary) !important;
}

.blockquote-footer {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

/* Projects */
/* Milestones */
/* Bugs */
/* Users */
.proj__item,
.bug__item,
.milestone__item,
.user__item {
  background-color: var(--color-bg-secondary);
  border-left: 4px solid var(--color-bg-secondary);
  border-right: 4px solid var(--color-bg-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
}

.proj__item:hover,
.milestone__item:hover,
.bug__item:hover,
.user__item:hover {
  border-left: 4px solid var(--color-util-main);
  border-right: 4px solid var(--color-util-main);
}

.proj__label,
.milestone__label,
.bug__label,
.user__label {
  font-size: 0.9em;
}

/* Table */
.table {
  color: var(--color-text-secondary);
}

/* Board */
#board th {
  width: 200px;
}

.board__bug {
  background-color: var(--color-bg-secondary);
  border-left: 4px solid var(--color-bg-secondary);
  border-right: 4px solid var(--color-bg-secondary);
}

.board__bug:hover {
  border-left: 4px solid var(--color-util-main);
  border-right: 4px solid var(--color-util-main);
}

/* Carousel */
.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.carousel-caption {
  background-color: rgb(250 250 250 / 70%);
  width: 100%;
  left: 0;
  right: 0;
}

/* Loader */
.loader {
  border: 16px solid var(--color-util-secondary);
  border-top: 16px solid var(--color-util-main);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Theme Switch */
.theme-switch {
  width: 61px;
  border: 2px solid var(--color-form-main);
  border-radius: 100px;
  background-color: var(--color-form-main);
}

.theme-switch__toggle {
  background-color: var(--color-form-disabled);
  position: absolute;
  border-radius: 100px;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: 1px;
}

.theme-switch__toggle--light {
  margin-left: 26px;
  animation: switchMoveRight 0.25s linear;
}

.theme-switch__toggle--dark {
  margin-left: -2px;
  animation: switchMoveLeft 0.25s linear;
}

@keyframes switchMoveRight {
  0% {
    margin-left: -2px;
  }

  100% {
    margin-right: 26px;
  }
}

@keyframes switchMoveLeft {
  0% {
    margin-left: 26px;
  }

  100% {
    margin-right: -2px;
  }
}